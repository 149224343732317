import {Layout, Menu, Button} from "antd";
// import logo from "../Common/Images/logo-full.png";
import {Link} from "react-router-dom";
import CustomFooter from "./CustomFooter";
import logo from 'Common/Images/framerly-wordmark.png'

const {Header} = Layout

const UserLogWrapper = ({ children }: any) => {
  
  return(
    <Layout className="is-fullheight">

      <Header
          style={{
              position: 'fixed',
              zIndex: 1,
              backgroundColor: '#ee233d', width: '100%' }}>
      <div>
            <img
              className="logo-img"
              alt="Framery"
              src={logo}
            />
          </div>
      </Header>

      <Layout
        className="site-layout is-fullheight"
        style={{
          minHeight: "80vh"
        }}
      >
        {children}
      </Layout>

      <CustomFooter />

    </Layout>
  )
}

export default UserLogWrapper