import React from "react";
import {Route, Redirect} from "react-router-dom";

type AuthRouteProps = {
  children: React.ReactNode,
  isAuthed: boolean,
  user: any,
  setUser?: Function,
  path: string
}

export const AuthRoute = ({
  children, isAuthed, user, setUser, path, ...rest
} : AuthRouteProps ) => {

  return(
    <Route
      render={() => isAuthed ? (
          <Redirect to={{
            pathname: '/'
          }} />
      ) : (
          children
      )}
    />
  )
}
