import React, { useState } from 'react'
import {
  Button,
  Card,
  Form,
  Input,
  message
} from 'antd'
import { setToken } from '../Common/ApiCalls/Logout'
import { BadNotif } from '../Common/Utils/SendNotification'
import RegisterUser from '../Common/ApiCalls/MainBackend/RegisterUser'
import { Link } from 'react-router-dom'

const Register = ({
  setUser
}: any) => {
  const [load, setLoad] = useState(false)

  const userRegister = (values: any) => {
    if (!values.email) {
      message.error("Invalid Email")
      return
    }
    if (!values.password) {
      message.error("Invalid Password")
      return
    }
    if (values.password.length < 8) {
      message.error("Password should be minimum 8 characters long")
      return
    }
    setLoad(true)
    RegisterUser(values.email, values.password)
      .then(r => {
        setLoad(false)
        if (r.success) {
          message.success("Register Successfully. Login TO Continue")
        } else {
          BadNotif(r)
        }
      })
  }

  return (
    <Card
      title="Register"
    >
      <Form
        hideRequiredMark
        onFinish={userRegister}
      >
        <Form.Item
          noStyle
        >
          Email
          <Form.Item
            required
            name="email"
          >
            <Input
              placeholder="joe@gmail.com"
              type='email'
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          Password
          <Form.Item
            required
            name="password"
          >
            <Input.Password
              type='password'
              placeholder="your password here"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          shouldUpdate
        >
          <Button
            htmlType='submit'
            type='primary'
            size='large'
            disabled={load}
          >
            {load  ? "Wait..." : "Submit"}
          </Button>
        </Form.Item>
        <Form.Item>
          Already Registered? <Link to="/sign-in">Sign In</Link>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default Register