import React from 'react'
import {
  Row,
  Col,
  Typography,
  Divider
} from 'antd'
import { Redirect } from 'react-router'
import UserLogWrapper from '../Components/UserLogWrapper'
import ContentWrapper from '../Components/ContentWrapper'
import Login from './Login'
import Register from './Register'

const { Title } = Typography

type AuthProps = {
  isAuthed: Boolean,
  setUser: Function,
  signIn: Boolean
}

const Auth = ({
  isAuthed,
  setUser,
  signIn
}: AuthProps) => {

  const formTitle = signIn ? "Sign In" : "Register";

  if (isAuthed) {
    return <Redirect
      to={{
        pathname: '/'
      }}
    />
  }

  return (
    <UserLogWrapper>
      <ContentWrapper>

        <Title>
          {formTitle}
        </Title>

        <Divider />

        <Row
          align='middle'
          justify='center'
        >
          <Col md={{ span: 12 }} xs={{ span: 22 }}>
            {
              signIn ? (
                <Login
                  setUser={setUser}
                />
              ) : (
                <Register />
              )
            }
          </Col>
        </Row>

      </ContentWrapper>
    </UserLogWrapper>
  )

}

export default Auth
