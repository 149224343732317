import React from "react";
import {Route, Redirect} from "react-router-dom";

export type PrivateRouteProps = {
  isAuthed: boolean,
  path?: string,
  Component: any,
  logoutUser: Function,
  refresh: Function,
  user: any
}

export const PrivateRoute = ({
  isAuthed, Component, logoutUser, refresh, user
}: PrivateRouteProps) => {

  return(
    <Route
      render={() => isAuthed ? (
        <Component
          logoutUser={logoutUser}
          refresh={refresh}
          user={user}
        />
      ) : (
        <Redirect to={{
            pathname: '/sign-in'
          }} 
        />
      )}
    />
  )
}
