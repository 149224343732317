import React, { useEffect, useState } from 'react'
import {
  Table,
  Typography,
  Avatar,
  Space,
  Button
} from 'antd'
import ContentWrapper from 'Components/ContentWrapper'
import InstaMedia from 'Common/Models/InstaMedia'
import moment from 'moment'
import { FixedType } from 'rc-table/lib/interface'

const {Title, Text} = Typography

type MediaDataProps = {
  tableTitle: string,
  data: InstaMedia[],
  loading: boolean
}

const MediaData = ({
  tableTitle,
  data,
  loading
}: MediaDataProps) => {

  const columns = [
    {
      key: 'media',
      title: 'Media',
      render: (i: InstaMedia) => {return (
        <Space>
          <Avatar
            size={64}
            shape="square"
            src={i.media_url}
          />
        </Space>
      )
      },
      width: 80,
      fixed: 'left'
    },
    {
      key: 'username',
      title: 'User who mentioned you',
      dataIndex: 'owner_username',
      fixed: 'left',
      width: 160
    },
    {
      key: 'media_type',
      title: 'Media Type',
      dataIndex: 'media_type',
      render: (media_type: string) => <Text>{media_type ? media_type.toUpperCase() : null}</Text>,
      width: 100
    },
    {
      key: 'likes',
      title: 'Likes',
      dataIndex: 'likes',
      width: 100
    },
    {
      key: 'comments',
      title: 'Comments',
      dataIndex: 'comments_count',
      width: 100
    },
/*     {
      key: 'engagement',
      title: 'Engagement',
      dataIndex: 'engagement',
      render: (i: any) => `${i}%`,
      width: 100
    }, */
/*     {
      key: 'impressions',
      title: 'Impressions',
      dataIndex: 'impressions',
      width: 130
    }, */
/*     {
      key: 'reach',
      title: 'Reach',
      dataIndex: 'reach',
      width: 100
    }, */
/*     {
      key: 'replies',
      title: 'Replies',
      dataIndex: 'replies',
      width: 100
    },
    {
      key: 'taps_back',
      title: 'Taps back',
      dataIndex: 'taps_back',
      width: 100
    },
    {
      key: 'taps_forward',
      title: 'Taps Forward',
      dataIndex: 'taps_forward',
      width: 120
    }, */
/*     {
      key: 'followers_count',
      title: 'Followers Count',
      dataIndex: 'followers_count',
      sorter: {
          compare: (a: InstaMedia, b: InstaMedia) => a.followers_count - b.followers_count,
          multiple :1
      },
      width: 130
    }, */
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'created_at',
      render: (i: string) => (
        moment(i).format("DD/MM/YYYY")
      ),
      width: 110
    },
    {
      key: 'time',
      title: 'Time',
      dataIndex: 'created_at',
      render: (i: string) => (
        moment(i).format("HH:mm")
      ),
      width: 100
    },
/*     {
      key: 'action',
      title: 'Action',
      width: 300,
      fixed: 'right',
      render: (item: InstaMedia) => (
        <Space>
          <Button href={item.media_url} target='_blank'>
            Open Media
          </Button>
        </Space>
      )
    } */
  ]

  useEffect(() => {
    console.log(data)
  }, [])

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: InstaMedia[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: InstaMedia) => ({
      disabled: record.username === 'Disabled User', // Column configuration not to be checked
      name: record.username,
    }),
  };

  const dataColumns = columns.map(item => ({
    ...item,
    ellipsis: true,
    fixed: (item.fixed ?? false) as FixedType
  }))

  return (
    <ContentWrapper
      style={{
        width: '100%',
        padding: '20px 10px',
        marginBottom: '40px'
      }}
    >
      <Title
        level={2}
        className="has-text-black"
      >
        {tableTitle}
      </Title>
      <Text
        className="has-text-black"
      >
        Here are details of media where your instagram account is tagged.
      </Text>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
        pagination={{
          position: ['topLeft'],
          pageSize: 100,
        }}
        scroll={{
          x: '1500'
        }}
        loading={loading}
        columns={dataColumns}
        dataSource={data}
        rowKey="id"
        bordered
      />
    </ContentWrapper>
  )
}

export default MediaData