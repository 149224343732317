import { GetRequest, PostRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import { ApiConstats, ServerURL } from "../ApiConstats";
import authHeader from "../auth-header";

const GetData = async (
  ig_account_id: string,
  category: string,
  page_number: number,
  page_size: number,
  sort_by?: string,
  sorting_status?: string,
) => {
  let apiResponse;
  if (ApiConstats.RUN_PROD_SERVER || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(ServerURL("/data"), {
      ig_account_id,
      category,
      page_number,
      page_size,
      sort_by,
      sorting_status
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    "data": {
        "count": 2
    },
    "success": true,
    "error": null
}
}

export default GetData
