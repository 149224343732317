import React from "react";
import NotificationTypeEnum from "../Models/NotificationTypeEnum";
import {message} from "antd";
import {SmileOutlined, FrownOutlined} from "@ant-design/icons";

function SendNotification(title: string, messageShow?: string, isLong=false) {
/*     let icon;
    } */
     let duration = 5;
    if (isLong) {
        duration = 20;
    }
    if (title === NotificationTypeEnum.Failure) {
        message.error(title, duration)
    } else {
        message.success(title, duration)
    }
}

export function BadNotif(r: any, message = "Something Wrong Happened") {
    if(r && r.errors) {message = r.errors[0].message}
    SendNotification(NotificationTypeEnum.Failure, message)
}

export function SuccessNotif(message = 'Done') {
    SendNotification(NotificationTypeEnum.Success, message)
}

export default SendNotification;