import {Table, Typography} from 'antd'
import ContentWrapper from 'Components/ContentWrapper'

const {Title} = Typography

type PeopleDataProps = {
  tableTitle: string,
  data: any[],
  loading: boolean
}

const PeopleData = ({
  tableTitle,
  data,
  loading
}: PeopleDataProps) => {

  const columns = [
    {
      key: 'id',
      title: 'Id',
      dataIndex: 'id'
    }
  ]

  return (
    <ContentWrapper
      style={{
        width: '100%',
        padding: '20px 10px',
        marginBottom: '10px'
      }}
    >
      <Title
        level={2}
        className="has-text-black"
      >
        {tableTitle}
      </Title>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          x: 1600
        }}
        sticky
        size='middle'
        pagination={{
          position: ['topRight']
        }}
      />
    </ContentWrapper>
  )
}

export default PeopleData