import {useEffect, useState} from 'react'
import getUserLocation from '../Common/ApiCalls/Events/getUserLocation'
import logout, { getToken, setToken } from '../Common/ApiCalls/Logout'
import {
  Layout
} from 'antd'
import {Switch, useHistory} from "react-router-dom";
import Loader from '../Components/Loader'
import { AuthRoute } from '../Components/CustomRoutes/AuthRoute'
import { PrivateRoute } from '../Components/CustomRoutes/PrivateRoute'
import { GeneralRoute } from '../Components/CustomRoutes/GeneralRoute'
import GetUserDetails from '../Common/ApiCalls/MainBackend/GetUserDetails'
import { BadNotif } from '../Common/Utils/SendNotification'
import Dashboard from '../Dashboard'
import RemoveCookie from '../Common/ApiCalls/Logout'
import Auth from '../Auth'
import { ConsoleSqlOutlined } from '@ant-design/icons'

const { Content } = Layout

declare global {
  interface Window {
    FB: any,
    Stripe: any,
  }
}

function useForceUpdate() {
  let [value, setState] = useState(true);
  return () => setState(!value);
}

const Home = () => {
  const [init, setInit] = useState(true)
  const [user, setUser] = useState(null)
  const forceUpdate = useForceUpdate()

  const login = (values: any) => {
    setToken(values.email?? "email")
  }

  const logoutUser = () => {
    RemoveCookie();
    setUser(null);
  }

  const refreshUserState = () => {
    setInit(true)
    GetUserDetails()
      .then(r => {
        if (r.success) {
          setUser(r.data)
        } else {
          BadNotif(r)
          RemoveCookie()
        }
        setInit(false)
      })
  }

  useEffect(() => {
    // getUserLocation()
    const token = getToken()
    if (token) {
      console.log("A Token Found.")
      refreshUserState();
    } else {
      console.log("No Existing Token.")
      setInit(false)
    }
  }, [])

  function checkTokenAndLogIn() {
    const token = getToken()
    if (token) {
      refreshUserState()
    }
  }

  if(init) {
    return (
      <Loader
        fullScreen
      />
    )
  }

  return (
    <Switch>

      <AuthRoute
        path="/sign-in"
        user={user}
        isAuthed={Boolean(user)}
        setUser={checkTokenAndLogIn}
      >
        <Auth
          signIn
          setUser={checkTokenAndLogIn}
          isAuthed={Boolean(user)}
        />
      </AuthRoute>

      <AuthRoute
        path="/register"
        user={user}
        isAuthed={Boolean(user)}
        setUser={checkTokenAndLogIn}
      >
        <Auth
          signIn={false}
          setUser={checkTokenAndLogIn}
          isAuthed={Boolean(user)}
        />
      </AuthRoute>

      <GeneralRoute
        path="/general"
        Component={() => (<div>General</div>)}
      />

      <PrivateRoute
        path="/"
        user={user}
        isAuthed={Boolean(user)}
        Component={Dashboard}
        refresh={refreshUserState}
        logoutUser={logoutUser}
      />

    </Switch>
  )
}

export default Home