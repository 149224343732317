import { PostRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import { ApiConstats, ServerURL } from "../ApiConstats";

const RegisterUser = async (
  email: string,
  password: string
) => {
  let apiResponse;
  if (ApiConstats.RUN_PROD_SERVER || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(ServerURL("/register"), {
      email,
      password
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    data: "ok",
    success: true,
    errors: []
  }
}

export default RegisterUser
