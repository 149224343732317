import { useEffect, useState } from 'react'
import { Avatar, BackTop, Button, Dropdown, Layout, Menu } from 'antd'
import {Route, Redirect, Switch} from "react-router";
import FbAccount from './FbAccount/FbAccount';
import { Link, NavLink } from 'react-router-dom'
import Homepage from './Homepage'
import { UserOutlined } from '@ant-design/icons';
import Profile from './Profile';
import OnBoard from './OnBoard';
import Data from './Data';
import GetInstaAccounts from '../Common/ApiCalls/MainBackend/GetInstaAccounts';
import { BadNotif } from '../Common/Utils/SendNotification';
import IgAccount from '../Common/Models/IgAccount';
import Loader from '../Components/Loader';
import FAQ from './FAQ';
import CustomFooter from '../Components/CustomFooter';
import logo from 'Common/Images/framerly-wordmark.png'

const {Content, Header, Footer} = Layout

type DashboardProps = {
  user: any,
  logoutUser: Function,
  refresh: Function
}

const Dashboard = ({
  user,
  logoutUser,
  refresh
}: DashboardProps) => {
  const [load, setLoad] = useState(true)
  const [ig_accounts, set_ig_accounts] = useState<IgAccount[]>([])

  useEffect(() => {
    refreshIgAccounts(true)
  }, [])

  async function refreshIgAccounts(initLoader = false) {
    if (initLoader) {
      setLoad(true)
    }
    await getIgAccounts()
    if (initLoader) {
      setLoad(false)
    }
  }

  async function getIgAccounts() {
    await GetInstaAccounts()
      .then(r => {
        if (r.success) {
          set_ig_accounts(r.data.accounts)
        } else {
          BadNotif(r)
        }
      })
    return true
  }

  const profileMenu = (
    <Menu
      style={{
        width: "150px"
      }}
    >
{/*       <Menu.Item
        style={{
          height: "45px"
        }}
        key="1"
      >
        <Link to="/profile">
          Profile
        </Link>
      </Menu.Item> */}
      <Menu.Item
        style={{
          height: "45px"
        }}
        key="2"
      >
        <Link to="/onboarding">
          Onboarding
        </Link>
      </Menu.Item>
      <Menu.Item
        style={{
          height: "45px"
        }}
        key="3"
        onClick={() => {
          logoutUser()
        }}
      >
        Log Out
      </Menu.Item>
    </Menu>
  )

  if (load) {
    return (
      <Loader
        fullScreen
      />
    )
  }

  return (
    <Layout className="is-fullheight">

      <BackTop />

      <Header
        style={{
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          backgroundColor: "#ee233d"
        }}>
        <div
          style={{
            width: "100%"
          }}
          className="is-flex is-justify-content-space-between is-align-items-center"
        >
          <div>
            <img
              className="logo-img"
              alt="Framery"
              src={logo}
            />
          </div>
{/*           <div>
            <Link
              to={'/'}
              className="has-text-white"
            >
              Influencerbit
            </Link>
          </div> */}
          <div
            className="is-flex is-justify-content-flex-end"
          >
            <Menu
              mode='horizontal'
              style={{
                backgroundColor: "#ee233d"
              }}
            >
              <Menu.Item>
                <NavLink
                  to={'/onboarding'}
                  className="has-text-white"
                >
                  Home
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  to={'/data'}
                  className="has-text-white"
                >
                  Data
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <Button
                  href={"https://framery.in"}
                  target='_blank'
                  type='link'
                  className="has-text-white"
                >
                  Go To Website
                </Button>
              </Menu.Item>
{/*               <Menu.Item>
                <NavLink
                  to={'/Support'}
                  className="has-text-white"
                >
                  Support
                </NavLink>
              </Menu.Item> */}
              <Menu.Item>
                <NavLink
                  to={'/faq'}
                  className="has-text-white"
                >
                  FAQs
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <Dropdown
                  overlay={profileMenu}
                  placement='bottomRight'
                >
                  <Avatar
                    shape='circle'
                    icon={<UserOutlined />}
                  />
                </Dropdown>
              </Menu.Item>
            </Menu>
          </div>
        </div>
      </Header>

      <Content
        className="site-layout"
        style={{
          padding: '0 0',
          marginTop: 64,
          minHeight: '82vh'
        }}
      >
        <Switch>
            <Route
              path="/"
              exact
              render={() => (
                <Homepage />
              )}
          />
          
          <Route
            path="/profile"
            exact
            render={() => (
              <Profile  
                user={user}
              />
            )}
          />

          <Route
              path="/connect_fb"
              exact
              component={() => (
                <FbAccount
                  user={user}
                  refreshIg={refreshIgAccounts}
                  igAccounts={ig_accounts}
                />
              )}
          />
          
          <Route
            path="/onboarding"
            exact
            render={() => (
              <OnBoard user={user} />
            )}
          />

          <Route
            path="/data"
            exact
            render={() => (
              <Data
                igAccounts={ig_accounts}
              />
            )}
          />

          <Route
            exact
            path="/faq"
            render={() => (
              <FAQ />
            )}
          />

          <Route
            path="/support"
            exact
            render={() => (
              <div>Support</div>
            )}
          />

            <Route
              render={() => (
                <div className="section">
                  <div className="container has-text-centered">
                    <div className="title">
                      You have lost. Go Back <Link to="/">Home</Link>
                    </div>
                  </div>
                </div>
              )}
            />
          </Switch>
      </Content>

      <CustomFooter />

    </Layout>
  )
}

export default Dashboard
