import React from "react";
import {Route} from "react-router-dom";

type props = {
  Component: any,
  path?: string
}

export const GeneralRoute = ({
  Component, 
  path,
  ...rest
} : props ) => {

  return(
    <Route
      render={props => (
        <Component
          {...props}
        />
      )}
    />
  )
}
