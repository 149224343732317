import React from 'react'
import ContentWrapper from '../../Components/ContentWrapper'
import {
  Row,
  Col,
  Typography,
  Button
} from 'antd'
import { Link, Redirect } from 'react-router-dom'

const {Title} = Typography

const Homepage = () => {

  return (
    <Redirect
      to={{
        pathname: '/onboarding'
      }}
    />
  )

  return (
    <ContentWrapper
    >
      <Row
        align='middle'
        gutter={24}
        justify='center'
      >
        <Col
          md={{span: 20}}
        >
          <Title
            level={3}
            className="has-text-black"
          >
            Welcome
          </Title>
          <Link
            to="/connect_fb"
          >
            Connect/View Fb
          </Link>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default Homepage