import { GetRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import { ApiConstats, ServerURL } from "../ApiConstats";
import authHeader from "../auth-header";

const GetInstaAccounts = async () => {
  let apiResponse;
  if (ApiConstats.RUN_PROD_SERVER || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(ServerURL("/ig_accounts"), null, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    "data": {
        "accounts": [
            {
                "id": 2,
                "follower_count": 44,
                "following_count": 8,
                "full_name": "Food Carva",
                "media_count": 10,
                "fb_id": "17841433925547264",
                "ig_id": "33978114851",
                "username": "_foodie_carva",
                "profile_pic_url": null,
                "is_business_account": true,
                "is_authed": null,
                "user_id": 1,
                "created_at": "2021-08-17T20:30:35.937Z",
                "updated_at": "2021-08-17T20:30:35.937Z",
                "fb_access_token_id": "4"
            },
            {
                "id": 3,
                "follower_count": 6,
                "following_count": 11,
                "full_name": "test account",
                "media_count": 1,
                "fb_id": "17841448652567654",
                "ig_id": "48541237752",
                "username": "tetsingacc234",
                "profile_pic_url": null,
                "is_business_account": true,
                "is_authed": null,
                "user_id": 1,
                "created_at": "2021-08-17T20:30:36.660Z",
                "updated_at": "2021-08-17T20:30:36.660Z",
                "fb_access_token_id": "4"
            }
        ],
        "count": 2
    },
    "success": true,
    "error": null
}
}

export default GetInstaAccounts
