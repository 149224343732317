import React from "react";
import {Spin} from "antd";

type LoaderProps = {
  fullScreen?: boolean
}

const Loader = ({
  fullScreen = false
}: LoaderProps) => {

  return (
    <div className={`hero is-dark ${fullScreen ? 'is-fullheight': ''}`}>
      <div className="hero-body">
      <div className="container has-text-centered">
            <div className="title">
              <Spin />
            </div>
          </div>
      </div>
    </div>
  )
}

export default Loader