import userEvent from '@testing-library/user-event'
import React from 'react'
import ContentWrapper from '../../Components/ContentWrapper'

type ProfileProps = {
  user: any
}

const Profile = ({
  user
}: ProfileProps) => {
  return (
    <ContentWrapper>
      Hello {user.email}
    </ContentWrapper>
  )
}

export default Profile