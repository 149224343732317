import React, { useEffect, useState } from 'react'
import {
  Layout,
  Menu,
  Typography,
  Table
} from 'antd'
import ContentWrapper from '../../../Components/ContentWrapper'
import Loader from '../../../Components/Loader'
import PeopleData from './PeopleData'
import MediaData from './MediaData'
import GetData from '../../../Common/ApiCalls/MainBackend/GetData'
import IgAccount from '../../../Common/Models/IgAccount'
import { BadNotif } from '../../../Common/Utils/SendNotification'
import InstaMedia from '../../../Common/Models/InstaMedia'
import InstaInfluencer from '../../../Common/Models/InstaInfluencer'

const { Content, Sider } = Layout
const {Title} = Typography

type menuItems = {
  key: string,
  title: string
}
type subMenuItems = {
  title: string,
  items: menuItems[],
  hide: boolean
}

const sideMenus: subMenuItems[] = [
  {
    title: 'People',
    hide: true,
    items: [
      {
        key: "1",
        title: "All People"
      },
      {
        key: "2",
        title: "Mentioned You"
      },
/*       {
        key: "3",
        title: "NanoInflueners"
      },
      {
        key: "4",
        title: "MicroInfluencers"
      },
      {
        key: "5",
        title: "Larger Influencers"
      }, */
/*       {
        key: "6",
        title: "New"
      },
      {
        key: "7",
        title: "Archived"
      } */
    ]
  },
  {
    title: "Media",
    hide: false,
    items: [
      {
        key: "8",
        title: "Your mentions"
      },
/*       {
        key: "9",
        title: "Top Engaged"
      },
      {
        key: "10",
        title: "New"
      } */
    ]
  }
]

type sectionType = {
  title: string,
  type: 'Media' | 'People'
}

type PeopleProps = {
  igAccount: IgAccount
}

const DEFAULT_PAGE_SIZE = 50
const DEFAULT_PAGE_NUMBER = 1
const DEFAULT_MENU_KEY = "8"
const PAGE_SIZE_OPTIONS = ["10", "25", "50", "100", "250"]

const Peoples = ({
  igAccount
}: PeopleProps) => {
  const [load, setLoad] = useState(true)
  const [section, setSection] = useState<sectionType>({
    title: 'random', type: 'People'
  })
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER)
  const [data, setData] = useState<any[]>([])

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  function getData() {
    loading()
    GetData(String(igAccount.id), section.title, pageNumber, pageSize)
      .then(r => {
        if (r.success) {
          setData(r.data.values)
        } else {
          BadNotif(r)
        }
        loaded()
      })
  }

  useEffect(() => {
    changeMenu(DEFAULT_MENU_KEY)
  }, [])

  useEffect(() => {
    getData()
  }, [section])

  function findItemInSubMenu(keyValue: string): [string | undefined, 'People' | 'Media'] {
    for (let i = 0; i < sideMenus.length; i++) {
      for (let j = 0; j < sideMenus[i].items.length; j++) {
        if (sideMenus[i].items[j].key === keyValue) {
          return [
            sideMenus[i].items[j].title,
            sideMenus[i].title === "People" ? "People" : "Media"
          ]
        }
      }
    }
    return [undefined, section.type]
  }

  function changeMenu(e: any) {
    const [t, sub] = findItemInSubMenu(e)
    if (t === undefined || sub === undefined) {
      console.log("invalid", t, sub)
      return
    }
    setSection({
      title: t,
      type: sub
    })
  }

  const getTableComponent = () => {
    switch (section.type) {
      case 'People':
        return (
          <PeopleData
            tableTitle={section.title}
            data={data}
            loading={load}
          />
        )
      case 'Media':
        return (
          <MediaData
            data={data}
            tableTitle={section.title}
            loading={load}
          />
        )
      default:
        return (
          <div>Invalid</div>
        )
    }
  }

  return (
    <ContentWrapper
      style={{
        padding: 0
      }}
      marginLess
    >
      <Layout
        className="m-0 p-0"
      >
        <Sider>
          <Menu
            mode='inline'
            onClick={(e) => changeMenu(e.key)}
          >
            {
              sideMenus.map((sideMenuItem) => {
                return sideMenuItem.hide ? null : (
                    <Menu.ItemGroup title={sideMenuItem.title}>
                    {sideMenuItem.items.map((menuItem) => (
                      <Menu.Item
                        key={menuItem.key ?? menuItem.title}
                      >
                        {menuItem.title}
                      </Menu.Item>
                    ))}
                  </Menu.ItemGroup>
                  )
              })
            }
          </Menu>
        </Sider>
        {getTableComponent()}
      </Layout>
    </ContentWrapper>
  )
}

export default Peoples