import 'App.css';
import 'Components/all.sass'
import Home from './Home';

function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
