import {GetRequest, PostRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import { ApiConstats, ServerURL } from "../ApiConstats";
import authHeader from "../auth-header";

const UpdateMessage = async (message: string) => {
  let apiResponse;
  if (ApiConstats.RUN_PROD_SERVER || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(ServerURL("/update_message"), {
      message
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    "data": "OK",
    "success": true,
    "error": null
  }
}

export default UpdateMessage
