import { Area, Line } from '@ant-design/charts';
import { Result } from 'antd';
import moment from 'moment';
import React from 'react'

type InstaGraphProps = {
  data: {value: number, end_time: string}[],
  name: string,
  period: string,
  description: string
}

const InstaGraph = ({
  data,
  name,
  period,
  description
}: InstaGraphProps) => {

  // moment('2021-08-20T07:00:00+0000').format("DD/MM/YYYY HH:mm::ss")
  const config = {
    data,
    height: 400,
    xField: 'end_time',
    yField: 'value',
    point: {
      size: 5,
      shape: 'diamond',
    },
    label: {
      style: {
        fill: '#aaa',
      },
    },
  };

  if (data.length === 0) {
    return (
      <Result
        status='warning'
        title='There are some problems with the metric. Try different one.'
      />
    )
  }

  return (
    <Area
      {...config}
      padding='auto'
      smooth
    />
  )

  return (
    <Line
      {...config}
      padding='auto'
      smooth
    />
  )
}

export default InstaGraph