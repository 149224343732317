import React from "react";
import {Layout} from "antd";

const {Content} = Layout

interface compProps {
  children: React.ReactNode,
  marginLess?: boolean,
  style?: React.CSSProperties,
  addToClassName?: string,
  theme?: 'light' | 'dark'
}

const ContentWrapper = ({
  style = {},
  marginLess = false,
  children,
  addToClassName = '',
  theme = 'light'
}: compProps) => {
  return(
    <Content
      style={style}
      className={`site-layout-background${theme==='light' ? '' : '-dark'} content-wrapper ${addToClassName} ` + (marginLess ? 'content-wrapper-margin-less ' : '')}
    >
      {children}
    </Content>
  )
}

export default ContentWrapper