import { GetRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import { ApiConstats, ServerURL } from "../ApiConstats";
import authHeader from "../auth-header";

const GetInsights = async (
  metrics: string[],
  ig_user_id: string,
  sinceTime: string,
  untilTime: string
) => {
  let apiResponse;
  if (false && ApiConstats.RUN_PROD_SERVER || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(ServerURL("/insights"), {
      metrics,
      ig_user_id,
      sinceTime,
      untilTime
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
      data: {
          ig_id: "5",
          insights: [
              {
                  name: "reach",
                  period: "day",
                  values: [
                      { value: 0, end_time: "2021-08-14T07:00:00+0000" },
                      { value: 0, end_time: "2021-08-15T07:00:00+0000" },
                      { value: 2, end_time: "2021-08-16T07:00:00+0000" },
                      { value: 0, end_time: "2021-08-17T07:00:00+0000" },
                      { value: 1, end_time: "2021-08-18T07:00:00+0000" },
                      { value: 0, end_time: "2021-08-19T07:00:00+0000" },
                      { value: 0, end_time: "2021-08-20T07:00:00+0000" },
                  ],
                  title: "Reach",
                  description:
                      "The total number of times that the business account's media objects have been uniquely viewed.",
                  id: "17841448652567654/insights/reach/day",
              },
          ],
      },
      success: true,
      error: null,
  };
}

export default GetInsights
