import Cookies from "universal-cookie"
import { ApiConstats } from "./ApiConstats"

const COOKIE_PATH = ApiConstats.AUTH_COOKIE_PATH
const COOKIE_DOMAIN = (process.env.NODE_ENV === "development"
  ? ApiConstats.DEV_DOMAIN : ApiConstats.PROD_DOMAIN)
const COOKIE_NAME = ApiConstats.AUTH_COOKIE_NAME

const cookies = new Cookies()

export function getToken(COOKIE_TO_FETCH = COOKIE_NAME) {
  return cookies.get(COOKIE_TO_FETCH)
}

export function setToken(token: string, COOKIE_NAME_TO_STORE = COOKIE_NAME) {
  console.log(COOKIE_NAME_TO_STORE, token, COOKIE_PATH, COOKIE_DOMAIN)
  cookies.set(COOKIE_NAME_TO_STORE, token, {
    path: COOKIE_PATH,
    domain: COOKIE_DOMAIN
  })
}

export default function RemoveCookie() {
  cookies.remove(COOKIE_NAME, {
    path: COOKIE_PATH,
    domain: COOKIE_DOMAIN
  })
  // window.location.reload()
  return true
}