import {Button, Form, Input, message, Modal} from "antd";
import {useEffect, useState} from "react";
import UpdateMessage from "../../Common/ApiCalls/MainBackend/UpdateMessage";
import {BadNotif, SuccessNotif} from "../../Common/Utils/SendNotification";

const UpdateMessageModal = ({
                              vis,
    cancel,
    message
                            }: any) => {
  const [messForm] = Form.useForm()
  const [load, setLoad] = useState(false)

  useEffect(() => {
    messForm.setFieldsValue({
      message: message
    })
  }, [])

  const submit = (v: any) => {
    setLoad(true)
    if(!v.message || v.message.length < 1) {
      message.error("Message is Required")
      setLoad(false)
      return
    }
    UpdateMessage(v.message)
        .then(r => {
          if(r.success) {
            SuccessNotif("Message Updated")
          } else {
            BadNotif(r)
          }
          setLoad(false)
        })
  }

  return(
      <Modal
        visible={vis}
        footer={null}
        onCancel={() => cancel()}
        title={"Update Message"}
        width={800}
      >
        <Form
          form={messForm}
          onFinish={submit}
        >
          <Form.Item>
            Message
            <Form.Item
              noStyle
              name={"message"}
            >
              <Input.TextArea />
            </Form.Item>
          </Form.Item>
          <Form.Item
            shouldUpdate
          >
            {() => (
                <Button
                  htmlType={'submit'}
                  loading={load}
                  disabled={load}
                >
                  Submit
                </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
  )
}

export default UpdateMessageModal