import { PostRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import { ApiConstats, ServerURL } from "../ApiConstats";
import authHeader from "../auth-header";

const AddAuthTokenToUser = async (
  user_token: string
) => {
  let apiResponse;
  if (ApiConstats.RUN_PROD_SERVER || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(ServerURL("/add_token"), {
      user_token
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    "data": {
        "id": 4,
        "app_id": 4264012250373006,
        "access_token_type": "USER",
        "application": "InfluencerBit",
        "data_access_expires_at": 1637007869,
        "expires_at": 0,
        "is_valid": true,
        "scopes": [
            "email",
            "pages_show_list",
            "instagram_basic",
            "instagram_manage_insights",
            "pages_read_engagement",
            "pages_manage_metadata",
            "public_profile"
        ],
        "granular_scopes": [
            {
                "scope": "pages_show_list",
                "target_ids": [
                    "107936384926736"
                ]
            },
            {
                "scope": "instagram_basic"
            },
            {
                "scope": "instagram_manage_insights"
            },
            {
                "scope": "pages_read_engagement"
            },
            {
                "scope": "pages_manage_metadata"
            }
        ],
        "fb_user_id": 2041990572621757,
        "token": "EAA8mGLeAW44BAOw06ZBuTgkZCuOTNk65K9fYT6pjQDq0pvoSRJRGByS92JCXIxFNXjTasQGN0TUBYMIdPufHZBtxa7cyuEBoMDzZAkH8lUepUZCCmFpUZCakiz183fpDfxwCZBU3O6w7wA0VlixxBUqZBF498LuZBwQ4iBhoh4QVGDXnY3NOpqn6cSEwSsY4WllBDVbOvNxLlDCLrhcK08MHSwtETLAwQKRoZD",
        "user_id": 1,
        "created_at": "2021-08-17T20:30:34.599Z",
        "updated_at": "2021-08-17T20:30:34.599Z"
    },
    "success": true,
    "error": null
}
}

export default AddAuthTokenToUser
