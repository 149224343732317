import { Dropdown, Typography, Layout, Menu, Select, Space, Table, message, DatePicker, Skeleton, Spin } from 'antd'
import { useEffect, useState } from 'react'
import IgAccount from '../../Common/Models/IgAccount'
import ContentWrapper from '../../Components/ContentWrapper'
import Loader from '../../Components/Loader'
import InstaGraph from './InstaGraph'
import moment from 'moment'
import { ConsoleSqlOutlined } from '@ant-design/icons'
import Peoples from './Peoples'
import GetInsights from '../../Common/ApiCalls/MainBackend/GetInsights'
import { BadNotif } from '../../Common/Utils/SendNotification'

const {Title} = Typography
const { Content, Sider } = Layout
const { SubMenu } = Menu
const {RangePicker} = DatePicker

type graphItem = {
  key: string,
  value: string,
  minmFollowers?: number,
  description?: string,
  maxDays?: 30,
  tip: string
}

type metrics = 'reach' | 'profile_views'

type InsightsObject = {
  description: string,
  id: string,
  name: string,
  period: string,
  title: string,
  values: {value: number, end_time: string}[]
}

type InsightsResponse = {
  ig_id: string,
  insights: InsightsObject[]
}

const dateRangeFormat = "DD/MM/YYYY"

const instaMetrics: graphItem[] = [
  {
    key: 'reach',
    value: 'Reach',
    tip: "The total number of times that the business account's media objects have been uniquely viewed."
  },
  {
    key: 'text_message_clicks',
    value: 'Text Message Clicks',
    description: 'Total number of taps on the text message link on your Profile',
    tip: "The total number of taps on the text message link in this profile"
  },
  {
    key: 'website_clicks',
    value: 'Website Clicks',
    description: 'Total number of taps on website link on your Profile',
    tip: "The total number of taps on the website link in this profile"
  },
  {
    key: 'profile_views',
    value: 'Profile Views',
    description: 'Total Users who viewed your profile in the period',
    tip: "The total number of users who have viewed the business account's profile within the specified period."
  },
  {
    key: 'online_followers',
    value: 'Online Followers',
    description: 'Total Followers online during the range',
    minmFollowers: 100,
    tip: "Total number of the IG User's followers who were online during the specified range."
  },
  {
    key: 'impressions',
    value: 'Impressions',
    description: 'Total Media Views',
    tip: "The total number of times that the business account's media objects have been viewed."
  },
  {
    key: 'follower_count',
    value: 'Follower Count',
    description: 'New Followers Each Day',
    minmFollowers: 100,
    maxDays: 30,
    tip: "Total number of new followers each day within the specified range"
  }
]

type DataProps = {
  igAccounts: IgAccount[]
}

const initialDateRange = [
  moment().subtract(7, 'd'),
  moment().endOf('day')
]

const Data = ({
  igAccounts
}: DataProps) => {
  const [load, setLoad] = useState(false)
  const [dateRange, setDateRange] = useState(initialDateRange)
  const [igAccount, setIgAccount] = useState<IgAccount | null>(null)
  const [metric, setMetric] = useState('')
  const [data, setData] = useState([
    { value: 0, end_time: "2021-08-14T07:00:00+0000" },
    { value: 0, end_time: "2021-08-15T07:00:00+0000" },
    { value: 2, end_time: "2021-08-16T07:00:00+0000" },
    { value: 0, end_time: "2021-08-17T07:00:00+0000" },
    { value: 1, end_time: "2021-08-18T07:00:00+0000" },
    { value: 0, end_time: "2021-08-19T07:00:00+0000" },
    { value: 0, end_time: "2021-08-20T07:00:00+0000" },
  ])
  const [insightResponse, setInsightResponse] = useState<InsightsObject | null>(null)

  const loading = () => setLoad(true)
  const loaded = () => setLoad(false)

  function onDateRangeChange(dates: any, dateStrings: any) {
    console.log('From: ', dateStrings[0], 'to:', dateStrings[1])
    const days = dates[1].diff(dates[0], 'days') + 1;
    if (days > 364) {
      message.error("Date range must be less than one Year")
      return
    }
    setDateRange(dates)
  }

  useEffect(() => {
    if (dateRange !== initialDateRange) {
      getInsights()
    }
  }, [dateRange])

  const onIgAccountChange = (id: number) => {
    for (let i = 0; i < igAccounts.length; i++) {
      if (igAccounts[i].id == id) {
        setIgAccount(igAccounts[i])
        getInsights(metric, igAccounts[i].id)
      }
    }
  }

  const onMetricChange = (e: any) => {
    setMetric(e)
    getInsights(e)
  }

  useEffect(() => {
    if (igAccounts.length > 0) {
      const igAccount = igAccounts[0]
      setIgAccount(igAccount)
      setMetric(instaMetrics[0].key)
      getInsights(instaMetrics[0].key, igAccount.id)
    }
  }, [igAccounts])

/*   if (load) {
    return (
      <Skeleton
        active
      />
    )
  } */

  if (!igAccount) {
    return (
      <Title>
        No Accounts Connected to show data
      </Title>
    )
  }

  if (!load && !insightResponse) {
    return (
      <Title>
        Something bad happened. Try Later
      </Title>
    )
  }

  function getInsights(
    metric_name = metric,
    ig_id = igAccount?.id,
    startTime = dateRange[0].startOf('day').unix(),
    endTime = dateRange[1].endOf('day').unix()
  ) {
    loading()
    console.log(metric_name, ig_id, startTime, endTime)
    GetInsights( [metric_name], String(ig_id), String(startTime), String(endTime) )
      .then(r => {
        if (r.success) {
          const data: InsightsResponse = r.data
          if (data.insights.length > 0) {
            const insight = data.insights[0].values
            setInsightResponse(data.insights[0])
            setData(insight) 
          } else {
            setData([])
            message.warn("Cannot show this metric. Try again later.")
          }
        } else {
          BadNotif(r)
        }
        loaded()
      })
  }

  const disabledDateRange = (current: any) => {
    return current && current > moment().endOf('day')
  }

  const dataGraphOptions = (
  <Select
    style={{
      width: "150px"
    }}
    size='large'
      defaultValue={instaMetrics[0].value}
      onChange={onMetricChange}
  >
    {instaMetrics.map((graphItem) => (
      <Select.Option
        value={graphItem.key}
        key={graphItem.key}
        title={graphItem.tip ?? graphItem.description}
      >
        {graphItem.value}
      </Select.Option>
    ))}
  </Select>
  )

  const IgAccountOption = (
    <Select
      size='large'
      style={{
        width: '170px'
      }}
      defaultValue={igAccounts[0].username}
      onChange={e => onIgAccountChange(Number(e))}
    >
      {igAccounts.map((igAccount) => (
        <Select.Option
          value={igAccount.id}
          key={igAccount.id}
          title={igAccount.username}
        >
          {igAccount.username}
        </Select.Option>
      ))}
    </Select>
  )

  return (
    <>
      <ContentWrapper
        style={{
          paddingRight: 20,
          paddingLeft: 20
        }}
        theme='dark'
        marginLess
        addToClassName="mb-6"
      >
        <Space
          direction='vertical'
          style={{
            width: '100%'
          }}
          size='large'
        >
          <Space
            size='large'
          >
            {dataGraphOptions}
            {igAccounts.length === 0 ? <Spin /> : IgAccountOption}
          </Space>
          <RangePicker
            ranges={{
              'Today': [moment().startOf('day'), moment().endOf('day')],
              'Yesterday': [moment().day(-1).startOf('day'), moment().day(-1).endOf('day')],
              'This Week': [moment().startOf('week'), moment()],
              'This Month': [moment().startOf('month'), moment()],
              'Last Month': [moment().month(-1).startOf('month'), moment().month(-1).endOf('month')],
              'This Year': [moment().startOf('year'), moment()]
            }}
            format={dateRangeFormat}
            disabledDate={disabledDateRange}
            onChange={onDateRangeChange}
            size='large'
            defaultValue={[dateRange[0], dateRange[1]]}
          />
          {
            !load && insightResponse ? (
              <InstaGraph
                data={data}
                name={metric}
                period={insightResponse.period}
                description={insightResponse.description}
              />
            ) : (
                <Skeleton
                  active
                />
            )
          }
        </Space>
      </ContentWrapper>
      <Peoples
        igAccount={igAccount}
      />
    </>
  )
}

export default Data